import { Character, CharacterItem, CreateCharacterInput, UpdateCharacterInput } from '@src/API';
import { CharacterType } from '@src/customGraphql/customModels';
import { ListData } from '@src/libs/models';
import captureException from '@src/services/loggerService';
import generateRandomSlugSuffix, { DefaultSlugifyOptions } from '@src/utils/random';
import slugify from 'slugify';
import { ICharacterRepo, RealCharacterRepo } from '../repositories/characterRepo';

export interface ICharacterService {
  get(id: string): Promise<Character | null>;
  create({
    characterType,
    displayName,
    description,
  }: {
    characterType: CharacterType;
    displayName: string;
    description: string;
  }): Promise<Character>;
  update({
    id,
    characterType,
    charname,
    displayName,
    description,
    strengths,
    flaws,
    gender,
    race,
    birthDate,
    age,
    role,
    hobbies,
    dislikes,
    profilePictureUrl,
  }: {
    id: string;
    characterType?: CharacterType;
    charname?: string;
    displayName: string;
    description: string;
    strengths?: string;
    flaws?: string;
    gender?: string;
    race?: string;
    birthDate?: string;
    age?: string;
    role?: string;
    hobbies?: string;
    dislikes?: string;
    profilePictureUrl?: string;
  }): Promise<Character>;
  listCharacters(limit?: number, nextToken?: string): Promise<ListData<Character> | null>;
  getByCharname(charname: string): Promise<Character | null>;
  getCharactersByUserId(userId: string): Promise<ListData<Character> | null>;
  createCharacterItem({
    characterId,
    artworkId,
    jobId,
  }: {
    characterId: string;
    artworkId?: string | null | undefined;
    jobId?: string | null | undefined;
  }): Promise<CharacterItem>;
  deleteCharacterItem(id: string): Promise<void>;
}

export class RealCharacterService implements ICharacterService {
  private characterRepo: ICharacterRepo;
  private slugOptions = DefaultSlugifyOptions;

  public constructor() {
    this.characterRepo = new RealCharacterRepo();
  }

  public async get(id: string): Promise<Character | null> {
    return await this.characterRepo.get(id);
  }

  public async create({
    id,
    userId,
    characterType,
    displayName,
    description,
    profilePictureUrl,
  }: {
    id?: string;
    userId: string;
    characterType: CharacterType;
    displayName: string;
    description: string;
    profilePictureUrl?: string | undefined;
  }): Promise<Character> {
    // Generate a charname using character.display_name, and a random numeric suffix
    // Example of a slug: Chibi-Rh1gAzQH
    const charname = slugify(displayName, this.slugOptions) + '-' + generateRandomSlugSuffix(5);

    const characterInput: CreateCharacterInput = {
      id,
      user_id: userId,
      character_type: characterType,
      charname: charname,
      display_name: displayName,
      description,
      profile_picture_url: profilePictureUrl,
    };

    const result: Character = await this.characterRepo.create(characterInput);
    return result;
  }

  public async update({
    id,
    characterType,
    charname,
    displayName,
    description,
    strengths,
    flaws,
    gender,
    race,
    birthDate,
    age,
    role,
    hobbies,
    dislikes,
    profilePictureUrl,
  }: {
    id: string;
    characterType?: CharacterType;
    charname?: string;
    displayName: string;
    description: string;
    strengths?: string;
    flaws?: string;
    gender?: string;
    race?: string;
    birthDate?: string;
    age?: string;
    role?: string;
    hobbies?: string;
    dislikes?: string;
    profilePictureUrl?: string;
  }): Promise<Character> {
    const characterInput: UpdateCharacterInput = {
      id: id,
      character_type: characterType,
      display_name: displayName,
      charname,
      description,
      strengths,
      flaws,
      gender,
      race,
      birth_date: birthDate,
      age,
      role,
      hobbies,
      dislikes,
      profile_picture_url: profilePictureUrl,
    };

    const result: Character = await this.characterRepo.update(characterInput);
    return result;
  }

  public async updateCharacterProfilePictureUrl({
    id,
    profilePictureUrl,
  }: {
    id: string;
    profilePictureUrl: string;
  }): Promise<Character> {
    const characterInput: UpdateCharacterInput = {
      id: id,
      profile_picture_url: profilePictureUrl,
    };

    const result: Character = await this.characterRepo.update(characterInput);
    return result;
  }

  public async listCharacters(
    limit?: number,
    nextToken?: string,
  ): Promise<ListData<Character> | null> {
    try {
      const result = await this.characterRepo.list(limit ? limit : 5000, nextToken);
      return result;
    } catch (error) {
      captureException(error);
      return null;
    }
  }

  public async getByCharname(charname: string): Promise<Character | null> {
    const result = await this.characterRepo.getByCharname(charname);
    return result;
  }

  public async getCharactersByUserId(
    userId: string,
    nextToken?: string,
  ): Promise<ListData<Character>> {
    const result = await this.characterRepo.getCharactersByUserId(userId, nextToken);
    return result;
  }

  public async createCharacterItem({
    characterId,
    artworkId,
    jobId,
  }: {
    characterId: string;
    artworkId?: string | null | undefined;
    jobId?: string | null | undefined;
  }): Promise<CharacterItem> {
    let itemType = 'JOB';
    if (artworkId) {
      itemType = 'ARTWORK';
    }

    const result = await this.characterRepo.createCharacterItem({
      character_id: characterId,
      item_type: itemType,
      artwork_id: artworkId,
      job_id: jobId,
    });
    return result;
  }

  public async deleteCharacterItem(id: string): Promise<void> {
    await this.characterRepo.deleteCharacterItem(id);
  }
}
