import * as httpErr from '@libs/httpErrors';
import {
  Character,
  CharacterByCharnameQuery,
  CharacterByCharnameQueryVariables,
  CharacterByUserIdQuery,
  CharacterByUserIdQueryVariables,
  CharacterItem,
  CreateCharacterInput,
  CreateCharacterItemInput,
  CreateCharacterItemMutation,
  CreateCharacterItemMutationVariables,
  CreateCharacterMutation,
  CreateCharacterMutationVariables,
  DeleteCharacterItemMutationVariables,
  GetCharacterQuery,
  ListCharactersQuery,
  UpdateCharacterInput,
  UpdateCharacterMutation,
  UpdateCharacterMutationVariables,
} from '@src/API';
import {
  customCreateCharacter,
  customCreateCharacterItem,
  customUpdateCharacter,
} from '@src/customGraphql/customCharacterMutations';
import {
  customCharacterByCharname,
  customGetCharacter,
  customListCharacters,
} from '@src/customGraphql/customCharacterQueries';
import { deleteCharacterItem } from '@src/graphql/mutations';
import { characterByUserId } from '@src/graphql/queries';
import { ListData } from '@src/libs/models';
import captureException from '@src/services/loggerService';
import { API } from 'aws-amplify';

export interface ICharacterRepo {
  get(id: string): Promise<Character | null>;
  create(character: CreateCharacterInput): Promise<Character>;
  update(character: UpdateCharacterInput): Promise<Character>;
  list(limit?: number, nextToken?: string): Promise<ListData<Character>>;
  getByCharname(charname: string): Promise<Character | null>;
  getCharactersByUserId(userId: string, nextToken?: string): Promise<ListData<Character>>;
  createCharacterItem(characterItem: CreateCharacterItemInput): Promise<CharacterItem>;
  deleteCharacterItem(id: string): Promise<void>;
}

export class RealCharacterRepo implements ICharacterRepo {
  public async get(id: string): Promise<Character | null> {
    const res = (await API.graphql({
      query: customGetCharacter,
      authMode: 'AWS_IAM',
      variables: { id },
    })) as { data: GetCharacterQuery };

    if (!res.data.getCharacter) return null;

    return res.data.getCharacter as Character;
  }

  public async create(character: CreateCharacterInput): Promise<Character> {
    const variables: CreateCharacterMutationVariables = {
      input: character,
    };

    let res = { data: {} } as { data: CreateCharacterMutation };
    try {
      res = (await API.graphql({
        query: customCreateCharacter,
        authMode: 'AWS_IAM',
        variables,
      })) as { data: CreateCharacterMutation };
    } catch (e) {
      captureException(e);
    }

    return res.data.createCharacter as Character;
  }

  public async update(character: UpdateCharacterInput): Promise<Character> {
    const variables: UpdateCharacterMutationVariables = {
      input: character,
    };

    let res = { data: {} } as { data: UpdateCharacterMutation };
    try {
      res = (await API.graphql({
        query: customUpdateCharacter,
        authMode: 'AWS_IAM',
        variables,
      })) as { data: UpdateCharacterMutation };

      if (!res.data.updateCharacter) {
        throw new Error('Character not updated.');
      }
    } catch (e) {
      captureException(e);
    }

    return res.data.updateCharacter as Character;
  }

  public async list(limit?: number, nextToken?: string): Promise<ListData<Character>> {
    const variables = {
      limit: limit as number,
      nextToken,
    };

    const res = (await API.graphql({
      query: customListCharacters,
      authMode: 'AWS_IAM',
      variables,
    })) as {
      data: ListCharactersQuery;
    };

    const result: ListData<Character> = {
      list: (res.data.listCharacters?.items || []) as Character[],
      nextToken: res.data.listCharacters?.nextToken as string,
    };

    return result;
  }

  public async getByCharname(charname: string): Promise<Character | null> {
    const variables: CharacterByCharnameQueryVariables = {
      charname: charname,
    };
    const res = (await API.graphql({
      query: customCharacterByCharname,
      authMode: 'AWS_IAM',
      variables: variables,
    })) as { data: CharacterByCharnameQuery };

    const characters = res.data.characterByCharname?.items;

    // If there is no user with the given username
    if (!characters || characters.length === 0 || !characters[0]) {
      return null;
    }
    // If there are duplicate charnames
    if (characters.length > 1) {
      throw new httpErr.NotFoundError(`Duplicate charname ${charname}.`);
    }
    return characters[0] as Character;
  }

  public async getCharactersByUserId(
    userId: string,
    nextToken?: string,
  ): Promise<ListData<Character>> {
    const variables: CharacterByUserIdQueryVariables = {
      user_id: userId,
      nextToken,
    };

    const res = (await API.graphql({
      query: characterByUserId,
      authMode: 'AWS_IAM',
      variables,
    })) as {
      data: CharacterByUserIdQuery;
    };

    const result: ListData<Character> = {
      list: (res.data.characterByUserId?.items || []) as Character[],
      nextToken: res.data.characterByUserId?.nextToken as string,
    };

    return result;
  }

  public async createCharacterItem(
    characterItem: CreateCharacterItemInput,
  ): Promise<CharacterItem> {
    const variables: CreateCharacterItemMutationVariables = {
      input: characterItem,
    };

    let res = { data: {} } as { data: CreateCharacterItemMutation };
    try {
      res = (await API.graphql({
        query: customCreateCharacterItem,
        authMode: 'AWS_IAM',
        variables,
      })) as { data: CreateCharacterItemMutation };
    } catch (e) {
      captureException(e);
    }

    return res.data.createCharacterItem as CharacterItem;
  }

  public async deleteCharacterItem(id: string): Promise<void> {
    const variables: DeleteCharacterItemMutationVariables = {
      input: {
        id,
      },
    };

    try {
      await API.graphql({
        query: deleteCharacterItem,
        authMode: 'AWS_IAM',
        variables,
      });
    } catch (e) {
      captureException(e);
    }
  }
}
