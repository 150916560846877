export const customGetCharacter = /* GraphQL */ `
  query GetCharacter($id: ID!) {
    getCharacter(id: $id) {
      id
      display_name
      charname
      character_type
      user_id
      user {
        id
        display_name
        username
        email_address
        bio
        is_artist
        profile_picture_url
        cover_picture_url
        device_tokens
        is_selling
        follower_quantity
        leader_quantity
        created_at
        updated_at
      }
      description
      strengths
      flaws
      gender
      race
      birth_date
      age
      role
      hobbies
      dislikes
      profile_picture_url
      cover_photo_url
      character_items {
        items {
          id
          artwork {
            id
            cover_image_height
            cover_image_width
            cover_image_src_url
            cover_image_thumbnail_url
            title
            likes_count
            comments_count
            description
            publication_date_time
            user_id
            user {
              id
              display_name
              first_name
              last_name
              username
              email_address
              is_artist
              profile_picture_url
              cover_picture_url
              is_selling
              created_at
              updated_at
              __typename
              seller_star_quantity
              seller_review_quantity
              buyer_star_quantity
              buyer_review_quantity
              follower_quantity
              leader_quantity
            }
            is_buyer_showcase
            is_seller_showcase
            created_at
            updated_at
            __typename
          }
        }
      }
      created_at
      updated_at
    }
  }
`;

export const customListCharacters = /* GraphQL */ `
  query ListCharacters($filter: ModelCharacterFilterInput, $limit: Int, $nextToken: String) {
    listCharacters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        display_name
        charname
        character_type
        user_id
        created_at
        updated_at
      }
      nextToken
    }
  }
`;

export const customCharacterByCharname = /* GraphQL */ `
  query CharacterByCharname(
    $charname: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCharacterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    characterByCharname(
      charname: $charname
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        display_name
        charname
        character_type
        user_id
        user {
          id
          display_name
          username
          email_address
          bio
          is_artist
          profile_picture_url
          cover_picture_url
          device_tokens
          is_selling
          follower_quantity
          leader_quantity
          created_at
          updated_at
        }
        description
        strengths
        flaws
        gender
        race
        birth_date
        age
        role
        hobbies
        dislikes
        profile_picture_url
        cover_photo_url
        character_items {
          items {
            id
            artwork {
              id
              cover_image_height
              cover_image_width
              cover_image_src_url
              cover_image_thumbnail_url
              title
              likes_count
              comments_count
              description
              publication_date_time
              user_id
              user {
                id
                display_name
                first_name
                last_name
                username
                email_address
                is_artist
                profile_picture_url
                cover_picture_url
                is_selling
                created_at
                updated_at
                __typename
                seller_star_quantity
                seller_review_quantity
                buyer_star_quantity
                buyer_review_quantity
                follower_quantity
                leader_quantity
              }
              is_buyer_showcase
              is_seller_showcase
              created_at
              updated_at
              __typename
            }
          }
        }
        created_at
        updated_at
      }
      nextToken
      __typename
    }
  }
`;
