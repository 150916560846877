export const customCreateCharacter = /* GraphQL */ `
  mutation CreateCharacter(
    $input: CreateCharacterInput!
    $condition: ModelCharacterConditionInput
  ) {
    createCharacter(input: $input, condition: $condition) {
      id
      display_name
      charname
      character_type
      user_id
      description
      strengths
      flaws
      gender
      race
      birth_date
      age
      role
      hobbies
      dislikes
      profile_picture_url
      cover_photo_url
      created_at
      updated_at
    }
  }
`;

export const customUpdateCharacter = /* GraphQL */ `
  mutation UpdateCharacter(
    $input: UpdateCharacterInput!
    $condition: ModelCharacterConditionInput
  ) {
    updateCharacter(input: $input, condition: $condition) {
      id
      display_name
      charname
      character_type
      user_id
      description
      strengths
      flaws
      gender
      race
      birth_date
      age
      role
      hobbies
      dislikes
      profile_picture_url
      cover_photo_url
      created_at
      updated_at
      __typename
    }
  }
`;

export const customCreateCharacterItem = /* GraphQL */ `
  mutation CreateCharacterItem(
    $input: CreateCharacterItemInput!
    $condition: ModelCharacterItemConditionInput
  ) {
    createCharacterItem(input: $input, condition: $condition) {
      id
      item_type
      character_id
      artwork_id
      job_id
      created_at
      updated_at
      __typename
    }
  }
`;

export const customDeleteCharacterItem = /* GraphQL */ `
  mutation DeleteCharacterItem(
    $input: DeleteCharacterItemInput!
    $condition: ModelCharacterItemConditionInput
  ) {
    deleteCharacterItem(input: $input, condition: $condition) {
      id
      item_type
      character_id
      artwork_id
      job_id
      created_at
      updated_at
      __typename
    }
  }
`;
